var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen flex flex-col main-layout"},[_c('div',{staticClass:"flex flex-row flex-grow xl:h-full relative"},[_c('sidebar'),_c('div',{staticClass:"w-full flex flex-col flex-nowrap",class:_vm.$route.name === 'consultations' ||
        _vm.$route.name === 'settings' ||
        _vm.$route.name === 'listPatients' ||
        _vm.$route.name === 'newPatients' ||
        _vm.$route.name === 'patientDetails' ||
        _vm.$route.name === 'socialNetwork' ||
        _vm.$route.name === 'dashboardPosologie'
          ? 'bg-bgGrey'
          : 'bg-white'},[_c('navbar'),_c('div',{staticClass:"flex-1 overflow-y-auto scroll-content pt-20",class:_vm.$route.name === 'consultationsN' ||
          _vm.$route.name === 'consultations' ||
          _vm.$route.name === 'page_support' ||
          _vm.$route.name === 'listPatients' ||
          _vm.$route.name === 'newPatients' ||
          _vm.$route.name === 'patientDetails' ||
          _vm.$route.name === 'page_support_discussion' ||
          _vm.$route.name === 'socialNetwork' ||
          _vm.$route.name === 'dashboardPosologie'
            ? ''
            : 'px-2 py-5'},[_c('router-view')],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }