<template>
  <div class="h-screen flex flex-col main-layout">
    <div class="flex flex-row flex-grow xl:h-full relative">
      <!-- Sidebar -->
      <sidebar />
      <div
        class="w-full flex flex-col flex-nowrap"
        :class="
          $route.name === 'consultations' ||
          $route.name === 'settings' ||
          $route.name === 'listPatients' ||
          $route.name === 'newPatients' ||
          $route.name === 'patientDetails' ||
          $route.name === 'socialNetwork' ||
          $route.name === 'dashboardPosologie'
            ? 'bg-bgGrey'
            : 'bg-white'
        "
      >
        <!-- Navbar -->
        <navbar />

        <div
          class="flex-1 overflow-y-auto scroll-content pt-20"
          :class="
            $route.name === 'consultationsN' ||
            $route.name === 'consultations' ||
            $route.name === 'page_support' ||
            $route.name === 'listPatients' ||
            $route.name === 'newPatients' ||
            $route.name === 'patientDetails' ||
            $route.name === 'page_support_discussion' ||
            $route.name === 'socialNetwork' ||
            $route.name === 'dashboardPosologie'
              ? ''
              : 'px-2 py-5'
          "
        >
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "./layouts/navbar/index";
import sidebar from "./layouts/sidebar";

export default {
  components: {
    navbar,
    sidebar
  }
};
</script>

<style>
.scroll-content::-webkit-scrollbar {
  width: 2px;
}

.scroll-content::-webkit-scrollbar-track {
  @apply bg-white;
}

.scroll-content::-webkit-scrollbar-thumb {
  @apply bg-dokBlue-ultra;
  height: 100px;
}

.scroll-content::-webkit-scrollbar-thumb:hover {
  @apply bg-dokBlue-ultra;
}
</style>
